export const ORS_DEPRECATION_FC_KEY =
  process.env.NEXT_PUBLIC_CONFIG_ENV === "demo"
    ? "b2b-ors-deprecation-demo"
    : "b2b-ors-deprecation";

export const FC_DOCUMENT_TYPE_NEW_BEHAVIOR = "b2b-document-type-new-behavior";

export const FC_RECENT_SEARCH = "b2b-recent-search";

export const FC_ENABLE_DEVTOOLS = "ctv-enabled-devtools";

export const FC_INVOICE_DISPUTE = "ctv-invoice-dispute";

export const FC_TERMS_AND_CONDITIONS_V2 = "b2b-terms-and-conditions-v2";

export const FC_FLIGHT_DIRECT_ONLY = "b2b-flight-direct-only";

export default {
  ORS_DEPRECATION_FC_KEY,
  FC_DOCUMENT_TYPE_NEW_BEHAVIOR,
  FC_RECENT_SEARCH,
  FC_ENABLE_DEVTOOLS,
  FC_INVOICE_DISPUTE,
  FC_TERMS_AND_CONDITIONS_V2,
  FC_FLIGHT_DIRECT_ONLY,
};
